<script setup lang="ts">
definePageMeta({
  title: "Elegant Jewelries: Redefining Standards",
  layout: "default",
  scrollToTop: true,
});
useHead({
  title: useRoute().meta?.title as string,
});

const { width } = useWindowSize();

const img = useImage();

// hero image
const pub_img_src = "https://bazari-images.fra1.cdn.digitaloceanspaces.com/";

const hero_img = computed(() => {
  return pub_img_src + "hero_bg_2.jpg";
  // if (width.value < 768) {
  //   return pub_img_src + "hero_bg_2.png";
  // } else {
  //   return pub_img_src + "hero_bg_2.jpg";
  // }
});

const hero_bg = {
  backgroundImage: `url(${img(hero_img.value, { format: "webp", width: width.value }, { provider: "weserv" })})`,
  // backgroundImage: '@/assets/images/hero_bg.png'
};

const new_arrivals = useNuxtApp().$init.new_arrivals;
const featured_products = useNuxtApp().$init.featured_products;


//seo
defineOgImageComponent('NuxtSeo', {
  title: 'Redefining Elegance',
  description: 'Explore our handcrafted jewelry, where sophistication meets artistry.',
  theme: '#0cc0b1',
  colorMode: 'dark',
  // siteLogo: '/logo_black_bg.png'
})

// defineOgImageScreenshot({
//   // colorScheme: 'dark',
//   delay: 500,
//   selector: '#hero'
// })
// defineOgImageComponent('BlogPost', {
//   title: 'Is this thing on?',
//   borderColor: 'green-300',
// })
</script>

<template>
  <!-- <ClientOnly> -->
  <div>
    <div id="hero"
      class="hero relative bg-no-repeat bg-cover lg:bg-center bg-primary-50/60 dark:bg-transparent before:absolute before:inset-0 before:bg-black/40 before:bg-blend-overlay lg:before:bg-black/40 before:block before:w-full before:h-full"
      :style="hero_bg">
      <UContainer data-aos="zoom-out" data-aos-duration="1500" data-aos-delay="500"
        class="relative z-[1] w-full h-screen md:min-h-[650px] lg:min-h-[730px] flex flex-col gap-5 justify-center text-primary-50 lg:text-primary-50 dark:text-primary-50">
        <h1 class="text-5xl lg:text-6xl xl:text-6xl font-LibreBaskerville ">
          Elegance Redefined
        </h1>
        <p class="text-base w-10/12 lg:w-full">
          Elevate your fashion statement with jewelry that resonates with your
          individuality
        </p>

        <div class="lg:relative place-self-center lg:mt-10 lg:place-self-auto absolute bottom-6">
          <NuxtLink to="/shop"
            class="w-fit flex justify-center items-center bg-teak-200 text-turquoise-900 font-medium text-base gap-2 px-6 py-3 rounded-xl shadow hover:ring-1 hover:ring-teak-500 dark:hover:ring-teak-400 hover:bg-transparent hover:text-teak-500 dark:hover:text-teak-400">
            <span>Shop Now</span>
            <CyUiIconsShoppingBag />
          </NuxtLink>
        </div>
      </UContainer>
    </div>

    <UContainer>
      <SectionCenterTitleDescription v-if="featured_products.length" title="Featured Jewels">
        <ClientOnly>
          <ProductCarouselV1 :products="(featured_products as any)" />
          <template #fallback>
            <div class="h-[300px]"></div>
          </template>
        </ClientOnly>
      </SectionCenterTitleDescription>
    </UContainer>


    <UContainer class="section space-y-12">

      <SectionCenterTitleDescription title="Discover Jewelry Collection">

        <div class="space-y-12 sm:space-y-16">
          <CategoryHomeListing :img="pub_img_src + 'gold_3.jpg'" title="Gold Plated" to="/shop/jewels/categories/gold"
            position="left" />

          <CategoryHomeListing class="sm:-mt-[60px]" :img="pub_img_src + 'brass_2.jpg'" title="Brass / Silver"
            to="/shop/jewels/categories/brass-silver-plating" position="right" />

          <CategoryHomeListing :img="pub_img_src + 'silver_2.jpg'" title="Silver 925"
            to="/shop/jewels/categories/silver92-5" position="left" />
        </div>

      </SectionCenterTitleDescription>


    </UContainer>

    <div class="bg-primary-100 dark:bg-primary-900/10 cy-section">
      <UContainer>
        <SectionCenterTitleDescription v-if="new_arrivals.length" title="New Arrivals">
          <ClientOnly>
            <ProductCarouselV1 :products="(new_arrivals as any)" />
            <template #fallback>
              <div class="h-[300px]">

              </div>
            </template>
          </ClientOnly>
        </SectionCenterTitleDescription>
      </UContainer>
    </div>

  </div>
  <!-- </ClientOnly> -->
</template>

<style scoped>
@media screen and (max-width: 1023px) {
  .hero {
    background-position: 60%, bottom;
  }
}

.silver {
  border-radius: 100px 100px 100px 0px;
}

.silver-inverse {
  border-radius: 0px 0px 100px 0px;
}
</style>
