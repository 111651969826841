<script setup>
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
// import type { Dict } from "~/types";
// import type { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
// import type { PropType } from "vue";
const props = defineProps({
  products: {
    type: Array,
    required: true,
  },
  autoplay: {
    type: Number,
    default: 5000
  },
  breakpoints: {
    type: Object,
    default: {
      380: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      639: {
        itemsToShow: 2,
        snapAlign: "start",
      },
      768: {
        itemsToShow: 2,
        snapAlign: "start",
      },
      840: {
        itemsToShow: 3,
        snapAlign: "start",
      },
      1300: {
        itemsToShow: 4,
        snapAlign: "start",
      },
    }
  }
});

const emits = defineEmits(['productClicked'])
</script>

<template>
  <div class="overflow-hidden">
    <Carousel v-if="products.length > 0" :autoplay="autoplay" :wrap-around="true" :breakpoints="breakpoints"
      :pause-autoplay-on-hover="true">
      <Slide v-for="product in products" :key="product.id" class="">
        <div class="carousel__item w-full px-5 min-h-fit">
          <ProductComponentV1 @click="() => emits('productClicked')" :product="product" class="w-full" />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>
